.article {
  max-width: 90rem;
  margin: 0 auto;

  &--title {
    text-transform: none;
    letter-spacing: 0.05em;
    word-break: break-word;
    margin-bottom: $padding * 0.25;
  }

  img {
    width: 100%;
    height: auto;
  }

  picture {
    display: block;
    margin-bottom: $padding;
    @include blend;

    @include max-size-s {
      margin-bottom: $padding * 0.5;
    }
  }

  figure.video {
    column-span: all;
    display: block;
    margin: $padding 0;
    width: 100%;
    padding-top: 9 / 16 * 100%;
    position: relative;
    @include blend;

    @include max-size-xs {
      margin: $padding * 0.5 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  p + figure.video {
    margin-top: $padding * 0.3;
  }

  &--columns {
    columns: 2;
    column-gap: $padding;
    column-fill: auto;
    min-height: 10rem;

    @include max-size-s {
      columns: 1;
    }
  }

  &--break {
    height: 0.2rem;
    width: 7rem;
    display: inline-block;
  }

  &--metadata {
    display: flex;
    align-items: center;
    margin-bottom: $padding;

    time {
      font-size: 1.5rem;
      font-weight: bold;
      margin-left: $padding / 2;
    }
  }

  &--links {
    position: sticky;
    z-index: 5000;
    margin: $padding 0;
    top: -0.2rem;
    bottom: -0.2rem;
    display: flex;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
    padding: $padding * 0.5 0;
    border-top: 0.2rem solid transparent;
    border-bottom: 0.2rem solid transparent;
  }

  &--link {
    font-family: $typography-heading-font-family;
    font-size: $typography-body-font-size;
    margin-right: $padding;
    text-transform: uppercase;
  }
}

.articles {
  &--card {
    display: block;
    max-width: none;
    text-decoration: none;

    &:hover {
      background-color: inherit !important;
    }

    h2 {
      font-size: 2rem;
    }

    .article--title {
      margin-bottom: $padding * 0.25;
    }

    .article--metadata, picture {
      margin-bottom: $padding * 0.5;
    }
  }
}
