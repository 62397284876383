@mixin blend {
  filter: grayscale(100%) contrast(180%);
  mix-blend-mode: multiply;
  opacity: 0.85;
  will-change: opacity, mix-blend-mode, filter;
  transform: translate3d(0, 0, 0);

  &:hover {
    @include unblend;
  }

  @supports not (mix-blend-mode: multiply) {
    filter: grayscale(100%) invert(10%) contrast(240%);
  }
}

@mixin unblend {
  opacity: 1;
  mix-blend-mode: inherit;
  filter: inherit;
}
