@import 'utilities/responsive';

// Sizes
$size-xs: 568px;
$size-m: 1000px;
$size-l: 1200px;
$size-xl: 1600px;

// Layout
$padding: 3rem;
$navigation-width: 6rem;
$navigation-width-mobile: 3.2rem;

// Themes
$themes: (
  'homepage': (#00FFB6, darken(#00FFB6, 42%)),
  'news': (#F0FF6E, darken(#F0FF6E, 62%)),
  'concepts': (#43EEE0, darken(#43EEE0, 52%)),
  'events': (#F22D2D, darken(#F22D2D, 50%)),
  'artists': (#9B7FFF, darken(#9B7FFF, 60%))
);

// Colors
$color-black: #032218;
$color-white: #F0FF6E; //$color-green;
$color: $color-black;

// Typography
$typography-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$typography-font-family: 'Bai Jamjuree', $typography-fallback;
$typography-font-size: 62.5%;
$typography-line-height: 1.35;
$typography-heading-font-family: 'Russo One', $typography-fallback;
$typography-heading-line-height: 1.1;
$typography-h1-font-size: 6rem;
$typography-h1-mobile-font-size: 4rem;
$typography-h2-font-size: 4rem;
$typography-h2-mobile-font-size: 3rem;
$typography-h3-font-size: 3.2rem;
$typography-h3-mobile-font-size: 2.4rem;
$typography-h4-font-size: 2.4rem;
$typography-h4-mobile-font-size: 2.2rem;
$typography-body-large-font-size: 2.2rem;
$typography-body-mobile-large-font-size: 1.8rem;
$typography-body-font-size: 1.8rem;
$typography-body-small-font-size: 1.6rem;
$typography-body-small-line-height: 1.2;
$typography-body-tiny-font-size: 1.4rem;
$typography-body-tiny-line-height: 1.1;
