.hero {
  &--logo {
    span { display: none; }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &--articles {
    padding: $padding 0;

    h2 {
      margin-bottom: $padding;
    }
  }

  &--media {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    margin-bottom: $padding;

    @include max-size-xs {
      margin-bottom: $padding * 0.5;
    }

    &--image {
      position: relative;
      background-size: contain;
      @include blend;
    }

    &--packed {
      margin-bottom: 0;
    }

    &--player {
      width: 75%;
      position: relative;
      flex: 1 1 75%;
    }

    &--player-wrapper {
      width: 100%;
      height: 0;
      padding-top: 4 / 10 * 100%; /* 10:4 Aspect Ratio */
      position: relative;
      overflow: hidden;

      > div,
      > video,
      > iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &--woef {
      flex: 1 1 25%;
      margin-left: $padding;
      display: flex;
      align-items: center;

      svg {
        width: 100%;
        height: auto;
      }

      @include max-size-xs {
        display: none;
      }
    }
  }

  &--banner {
    padding: $padding * 0.5 $padding;

    @include max-size-xs {
      padding: $padding * 0.25 $padding * 0.5;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

