.grid {
  opacity: 0;

  &--static {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .grid--item {
      margin: 0 !important;
    }
  }

  &--setup {
    opacity: 1;
  }

  &--sizer, &--item {
    width: calc(33% - #{$padding});

    @include max-size-s {
      width: calc(50% - #{$padding * .5});
    }

    @include max-size-xs {
      width: 100%;
      padding-bottom: $padding * 0.5;
    }
  }

  &--woef {
    transform: rotate(13deg) scale(.6);

    &:hover {
      background-color: inherit !important;
      transform: rotate(32deg) scale(-.6);
    }
  }

  &--gutter {
    width: $padding;
  }
}
