$plyr-color-main: $color-white;

@import "~plyr/src/sass/plyr.scss";

video {
  opacity: 0;
}

.plyr {
  min-width: 0;
  cursor: pointer;

  // &--playing {
  //   @include unblend;
  // }

  video {
    opacity: 1;
  }

  &--video {
    background: none;
  }

  &__video-wrapper {
    @include blend;
  }

  &__video-wrapper, video {
    height: 100%;
  }

  &__control {
    width: 10rem !important;
    height: 10rem !important;
    box-shadow: none !important;
    background: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10rem !important;
      height: 10rem !important;
    }

    @include max-size-xs {
      svg {
        transform: scale(0.5);
      }
    }
  }
}
