.fill {
  margin: 0 (-$padding);
  width: calc(100% + #{$padding * 2});

  @include max-size-xs {
    margin: 0 (-$padding * 0.5);
    width: calc(100% + #{$padding});
  }

  &--trail {
    position: relative;

    &:before {
      z-index: 1000;
      pointer-events: none;
      content: " ";
      mix-blend-mode: screen;
      position: absolute;
      top: 0;
      left: -$navigation-width;
      width: $navigation-width;
      height: 100%;

      @include max-size-xs {
        left: -$navigation-width;
      }

      @supports not (mix-blend-mode: screen) {
        opacity: 0.25;
      }
    }
  }
}
