.two {
  &--columns {
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    @include max-size-xs {
      flex-wrap: wrap;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &--media {
    flex: 1 1 25%;
    position: relative;

    svg {
      position: absolute;
      bottom: - $padding * 0.213;
      left: $padding * 1.314;
    }

    @include max-size-xs {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $padding;

      svg {
        position: relative;
        bottom: inherit;
        left: inherit;
      }
    }
  }

  &--body {
    width: 75%;
    max-width: 50rem;

    @include max-size-xs {
      width: 100%;
    }
  }
}
