html,
body {
  font-size: $typography-font-size;
  font-family: $typography-font-family;
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: $typography-heading-font-family;
  line-height: $typography-heading-line-height;
  font-weight: normal;
  margin: 0 0 ($padding * 0.7) - ($typography-heading-line-height * 1rem) 0;
  letter-spacing: 0.2em;
}

h1 {
  font-size: $typography-h1-font-size;
  margin-bottom: $padding;

  @include max-size-xs {
    font-size: $typography-h1-mobile-font-size;
  }
}

h2 {
  font-size: $typography-h2-font-size;

  @include max-size-xs {
    font-size: $typography-h2-mobile-font-size;
  }
}

h3 {
  font-size: $typography-h3-font-size;

  @include max-size-xs {
    font-size: $typography-h3-mobile-font-size;
  }
}

h4 {
  font-size: $typography-h4-font-size;

  @include max-size-xs {
    font-size: $typography-h4-mobile-font-size;
  }
}

a {
  color: inherit;
  text-underline-position: under;

  &:active {
    color: inherit;
  }
}

p {
  font-size: $typography-body-font-size;
  line-height: $typography-line-height;
  margin: 0 0 ($padding) - ($typography-line-height * 1rem) 0;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
}
