.concepts {
  width: 100%;
  // columns: 3;
  // column-gap: $padding;
  // column-fill: balance;

  &--card {
    // width: 100%;
    display: block;
    text-decoration: none;
    margin-bottom: $padding;

    &:hover {
      background-color: inherit !important;
    }

    picture {
      @include blend;
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
