@mixin theme($white, $black) {
  background-color: $white;
  color: $black;

  svg {
    fill: $black;
  }

  ::selection {
    background-color: $black;
    color: $white;
  }

  ::-moz-selection {
    background-color: $black;
    color: $white;
  }

  .color-white {
    color: $white;
  }

  .color-black {
    color: $black;
  }

  .border-white {
    border-color: $white;
  }

  .border-black {
    border-color: $black;
  }

  .fill-white {
    fill: $white;
  }

  .fill-black {
    fill: $black;
  }

  .background-white {
    background-color: $white;
  }

  .background-black {
    background-color: $black;
  }

  a:hover {
    background-color: $black;

    &.color-white {
      background-color: $white;
    }
  }

  .plyr {
    background-color: $white;

    &__control svg {
      fill: $black;
    }
  }

  .fill--trail:before {
    background: $black;
  }
}

@each $name, $colors in $themes {
  .theme-#{$name} {
    @include theme(nth($colors, 1), nth($colors, 2));

    .invert {
      @include theme(nth($colors, 2), nth($colors, 1));
    }
  }
}
