.doggy {
  position: relative;
  display: block;
  width: $padding * 0.75;
  height: $padding * 0.75;
  margin: $padding * 0.875 auto $padding * 0.25 auto;

  @include max-size-xs {
    margin-top: $padding * 0.525;
  }

  svg {
    display: block;
    width: $padding * 0.6;
    height: $padding * 0.6;
    margin: 0 auto;

    @include max-size-xs {
      width: $padding * 0.5;
      height: $padding * 0.5;
    }
  }
}

nav {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  width: $navigation-width;
  background: var(--black);
  color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  font-weight: bolder;

  @include max-size-xs {
    width: $navigation-width-mobile;
  }

  ul {
    flex: 1 1 50%;

    a {
      position: relative;
      display: inline-block;
      line-height: $navigation-width;
      padding: $padding * 0.5 0;
      font-size: $navigation-width * 0.45;
      writing-mode: vertical-lr;
      transform-origin: 50% 50%;
      text-decoration: none;

      @include max-size-xs {
        line-height: $navigation-width-mobile;
        font-size: $navigation-width-mobile * 0.45;
        padding: $padding * 0.25 0;
      }

      &:hover {
        background: none !important;
        transform: skewY(34deg) rotate(-180deg) translateX(15%);
        text-decoration: underline;
      }

      &:active {
        opacity: 0;
      }
    }
  }

  h2 {
    font-size: 1rem;
    font-family: $typography-font-family;
    line-height: 2rem;
    letter-spacing: 0.2rem;
    text-align: center;
    margin: 0;

    @include max-size-xs {
      display: none;
    }

    a {
      text-decoration: none;
    }
  }
}

