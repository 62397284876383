*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

::-ms-clear {
  display: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

main {
  flex: 1 1 100%;
  padding: $padding;

  @include max-size-xs {
    padding: $padding * 0.5;
  }
}

.wrapper {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

svg {
  path, g, mask {
    fill: inherit;
    stroke: inherit;
  }
}
