.artists {
  width: 100%;

  &--break {
    height: 0.2rem;
    width: 4rem;
    background: $color-black;
    margin: $padding * 0.5 0;
  }

  &--card {
    display: block;
    text-decoration: none;
    margin-bottom: $padding;

    &:hover {
      background-color: inherit !important;
    }

    picture {
      @include blend;
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
